import UsersSvg from "../../../public/assets/svg/users-svgrepo-com.svg";
import FeedbackSvg from "../../../public/assets/svg/feedback-svgrepo-com.svg";
import MailSvg from "../../../public/assets/svg/mail-svgrepo-com.svg";
import TemplateSvg from "../../../public/assets/svg/template-svgrepo-com.svg";
import HistorySvg from "../../../public/assets/svg/history-svgrepo-com.svg";
import SettingSvg from "../../../public/assets/svg/settings-2-svgrepo-com.svg";
import SettingOptionSvg from "../../../public/assets/svg/settings-menu-option.svg";
import KeySvg from "../../../public/assets/svg/key-svgrepo-com.svg";
import MonitorSvg from "../../../public/assets/svg/monitor-svgrepo-com.svg";
import ErrorSvg from "../../../public/assets/svg/error-16-svgrepo-com.svg";
import EventSvg from "../../../public/assets/svg/events-svgrepo-com.svg";
import SystemSvg from "../../../public/assets/svg/system-expired-line-svgrepo-com.svg";
import SystemSettingsSvg from "../../../public/assets/svg/settings-general.svg";
import SystemLogsSvg from "../../../public/assets/svg/code-tech-dev-svgrepo-com.svg";
import SystemUpdateSvg from "../../../public/assets/svg/update-svgrepo-com.svg";
import DashboardSvg from "../../../public/assets/svg/dashboard-svgrepo-com.svg";
import AdminSvg from '../../../public/assets/svg/admin-with-cogwheels-svgrepo-com.svg';
import type { SvgIconProps } from "../types/svgIconComponsets";
//------------Template-----------------

import LanguageSvg from '../../../public/assets/svg/language-svgrepo-com.svg';
import CharacterSvg from '../../../public/assets/svg/woman-avatar-svgrepo-com.svg';
import QuoteSvg from '../../../public/assets/svg/quote-svgrepo-com.svg';
import TimeSvg from '../../../public/assets/svg/time-svgrepo-com.svg';
import BoxSvg from '../../../public/assets/svg/box-svgrepo-com.svg';
import ComboSvg from '../../../public/assets/svg/puzzle-svgrepo-com.svg'

export type MenuDesingModel = {
  href: string;
  icon: SvgIconProps;
  title: string;
};

type MenuSystemModel = {
  href?: string;
  icon: SvgIconProps;
  title: string;
  list?: MenuDesingModel[];
};

export const menuSystem: MenuSystemModel[] = [
  {
    href: "/dashboard",
    icon: DashboardSvg,
    title: "dashboard",
  },
  {
    icon: UsersSvg,
    title: "users",
    list: [
      {
        href: "/users",
        icon: UsersSvg,
        title: "players",
      },
      {
        href: '/admins',
        icon: AdminSvg,
        title: 'admins'
      }
    ]
  },
  // {
  //   href: "/feedbacks",
  //   icon: FeedbackSvg,
  //   title: "feedback",
  // },
  {
    icon: LanguageSvg,
    title: 'languages',
    list: [
      {
        href: '/languages',
        icon: LanguageSvg,
        title: 'languages',
      },
      {
        href: '/phrases',
        icon: QuoteSvg,
        title: 'phrases'
      }
    ]
  },
  {
    href: '/characters',
    icon: CharacterSvg,
    title: 'characters'
  },
  {
    href: '/coin-boxes',
    icon: BoxSvg,
    title: 'coinBoxes'
  },
  {
    href: '/time-test',
    icon: TimeSvg,
    title: 'editTime'
  },
  {
    href: "/global-events",
    icon: EventSvg,
    title: "globalEvents",
  },
  {
    icon: MailSvg,
    title: "mailTemplates",
    list: [
      {
        href: "/email-templates",
        icon: TemplateSvg,
        title: "templates",
      },
      {
        href: "/email-history",
        icon: HistorySvg,
        title: "history",
      },
    ],
  },
  // {
  //   icon: SettingSvg,
  //   title: "settings",
  //   list: [
  //     {
  //       href: "/settings",
  //       icon: SettingOptionSvg,
  //       title: "settings",
  //     },
  //     {
  //       href: "/configuration/keys",
  //       icon: KeySvg,
  //       title: "keyConfigurator",
  //     },
  //   ],
  // },
  {
    icon: MonitorSvg,
    title: "appOperation",
    list: [
      {
        href: "/app-logs",
        icon: ErrorSvg,
        title: "errorLogs",
      },
      {
        href: "/events",
        icon: EventSvg,
        title: "events",
      },
    ],
  },
  {
    icon: SystemSvg,
    title: "system",
    list: [
      // {
      //   href: "/common-settings",
      //   icon: SystemSettingsSvg,
      //   title: "generalSettings",
      // },
      {
        href: "/logs",
        icon: SystemLogsSvg,
        title: "logs",
      },
      {
        href: "/update-system",
        icon: SystemUpdateSvg,
        title: "systemUpdate",
      },
    ],
  },
];
