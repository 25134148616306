export const NAV = {
  ACCOUNT: 'account',
  DASHBOARD: 'dashboard',
  USER_INFO: 'users/info/:id',
  USER_EDIT: 'users/edit/:id',
  // USER_ADD: 'users/add',
  USER_LIST: 'users',
  ADMIN_LIST: 'admins',
  ADMIN_ADD: 'admins/add',
  ADMIN_INFO: 'admins/info/:id',
  // FEEDBACK_EDIT: 'feedbacks/edit/:id',
  // FEEDBACK_LIST: 'feedbacks',
  EMAIL_TEMPLATES: 'email-templates',
  EMAIL_HISTORY: 'email-history',
  EMAIL_CREATE: 'email-templates/create',
  EMAIL_EDIT: 'email-templates/edit/:id',
  LOGS_LIST: 'app-logs',
  // APP_STATISTICS: 'app-statistics',
  APP_STATISTICS_EVENTS: 'events',
  APP_STATISTICS_ADD: 'events/add',
  APP_STATISTICS_EDIT: 'events/edit/:id',
  LOGS: 'logs',
  // COMMON_SETTINGS: 'common-settings',
  UPDATE_SYSTEM: 'update-system',
  // SETTINGS: 'settings',
  // CONFIGURATION_KEYS: 'configuration/keys',
  // CONFIGURATION_KEYS_ADD: 'configuration/keys/add',
  // CONFIGURATION_KEYS_EDIT: 'configuration/keys/edit/:id',
  LOGIN: 'login',
  MAIN: "/",
  NO_PAGE: "*",
  //------Template---------

  LANGUAGES: 'languages',
  LANGUAGES_EDIT: 'languages/edit/:id',
  CHARACTERS: 'characters',
  PHRASES: 'phrases',
  TRANSLATION_ADD: 'phrases/info/add-translation/:id',
  TRANSLATION_EDIT: 'phrases/info/edit-tranlation/:id',
  PHRASES_INFO: 'phrases/info/:id',
  CHARACTERS_ADD: 'character/add',
  CHARACTER_INFO: 'character/info/:id',
  CHARACTER_EDIT: 'character/edit/:id',
  CHARACTER_FULL_INFO: '/character/info/full/:id/:langId',
  CHARACTER_INFO_ADD: '/character/info/add/:girlId',
  CHARACTER_INFO_EDIT: '/character/info/edit/:girlId/:langId',
  LEVEL_ADD: '/character/info/add-level/:girlId/:girlName',
  LEVEL_EDIT: '/character/info/edit-level/:girlId/:girlName/:level',
  LEVEL_TTF_ADD: '/character/info/add-level-ttf/:girlId/:girlName',
  LEVEL_TTF_EDIT: '/character/info/edit-level-ttf/:girlId/:girlName/:levelId',
  LEVEL_TTU_LIST: '/character/info/ttu-levels/:girlId/:girlName/',
  LEVEL_TTF_LIST: '/character/info/ttf-levels/:girlId/:girlName/',
  DIALOGS: '/character/dialogs/:girlId/:girlName',
  DIALOGS_QUESTION_ADD: '/character/dialogs/question-add/:girlId/:girlName',
  DIALOGS_QUESTION_EDIT: '/character/dialogs/question-edit/:girlId/:girlName/:questionId',
  DIALOGS_QUESTION_INFO: '/character/dialogs/info/:girlId/:girlName/:questionId',
  DIALOGS_ANSWER_ADD: '/character/dialogs/info/add-answer/:levelId/:languageId/:langName/:levelNum/:girlId/:questionId/:girlName',
  DIALOGS_ANSWER_EDIT: '/character/dialogs/info/edit-answer/:girlId/:questionId/:girlName/:answerId',
  DIALOG_ANSWER_INFO: '/character/dialogs/info/info-answer/:girlId/:questionId/:girlName/:answerId',
  QUEST_ADD: '/character/quests/add-quest/:girlId/:girlName/',
  QUEST_EDIT: '/character/quests/edit-quest/:girlId/:girlName/:questId',
  QUESTONS_QUEST_LEVEL: '/character/quests/questions-level/:girlId/:girlName/:levelId/:questId/',
  QUESTONS_QUEST_LEVEL_ADD: '/character/quests/questions-level-add/:girlId/:girlName/:levelId/:questId/',
  QUESTONS_QUEST_LEVEL_EDIT: '/character/quests/questions-level-edit/:girlId/:girlName/:levelId/:questId/:questionQuestId',
  QUESTONS_QUEST_INFO: '/character/quests/question-quest-info/:girlId/:girlName/:levelId/:questId/:questionQuestId/',
  ANSWER_QUEST_ADD: '/character/quests/answer-quest-add/:girlId/:girlName/:levelId/:questId/:questionQuestId/:languageId/:langName/',
  ANSWER_QUEST_EDIT: '/character/quests/answer-quest-edit/:girlId/:girlName/:levelId/:questId/:questionQuestId/:languageId/:langName/:answerQuestId',
  ANSWER_QUEST_INFO: '/character/quests/answer-quest-info/:girlId/:girlName/:levelId/:questId/:questionQuestId/:languageId/:langName/:answerQuestId',
  TEST_TIME: 'time-test',
  USER_EVENT_DIALOG: '/users/info/history-dialogs/:userId/:userName/:eventId',
  USER_EVENT_QUEST: '/users/info/history-quest/:userId/:userName/:eventId',
  USER_REFERRALS: '/users/info/referrals/:userId/:userName',
  LEVEL_TTF_INFO: '/character/info/level/:girlId/:girlName/:levelId',
  QUEST_TTF_INFO: '/character/info/ttf-levels/level/quest/:girlId/:girlName/:levelId',
  QUEST_LIST: '/character/quests/:girlId/:girlName/',
  QUEST_INFO_GENERAL: '/character/quests/:girlId/:girlName/:questId',
  GLOBAL_EVENTS: 'global-events',
  GLOBAL_EVENT_INFO: '/global-events/event/:eventId',
  ITEMS_CHARACTER_PAGE: '/character/items/:girlId/:girlName',
  GIFTS_CHARACTER_PAGE: '/character/gifts/:girlId/:girlName',
  ITEM_CHARACTER_ADD: '/character/items/add-item/:girlId/:girlName',
  GIFT_CHARACTER_ADD: '/character/gifts/add-gift/:girlId/:girlName',
  GIFT_CHARACTER_EDIT: '/character/gifts/edit-gift/:girlId/:girlName/:giftId',
  ITEM_CHARACTER_EDIT: '/character/items/edit-item/:girlId/:girlName/:itemId',
  COIN_BOXES: 'coin-boxes',
  COIN_BOXES_ADD: 'coin-boxes/add',
  COIN_BOXES_EDIT: 'coin-boxes/edit/:id',
  COMBO_PAGE: '/character/combo/:girlId/:girlName',
  COMBO_ADD: '/character/combo/add-combo/:girlId/:girlName',
  COMBO_EDIT: '/character/combo/edit-combo/:girlId/:girlName/:comboId',
  GIFT_INFO_PAGE: '/character/gifts/gift-info/:girlId/:girlName/:giftId',
  ITEM_INFO_PAGE: '/character/items/item-info/:girlId/:girlName/:itemId'
};
