import { t } from "i18next";

export interface ValidateObjectType {
  [key: string]: string | undefined;
  email?: string;
  phone?: string;
  username?: string;
  inn?: string;
  number?: string;
  date?: string;
  message?: string;
  repeadPassword?: string;
  fio?: string;
  login?: string;
  companyName?: string;
  password?: string;
  newPassword?: string;
  oldPassword?: string;
  coursesName?: string;
  langName?: string;
  nameCharacter?: string;
}

export class Validator {

  static validate<T extends object>(obj: T, required: (keyof T)[]): T | null {
    const newErrors: T & Partial<ValidateObjectType> = {} as T & Partial<ValidateObjectType>;

    for (let field in obj) {

      const isRequired = required.includes(field);
      const value = obj[field];

      if (!isRequired && !value) {
        continue;
      }

      const validateMethod = this[field as keyof typeof Validator];

      if (validateMethod && typeof validateMethod === "function") {
        //@ts-ignore
        const error = validateMethod.call(this, value, isRequired);
        if (error) {
          newErrors[field] = error;
        }
      }
    }

    if (obj.hasOwnProperty('repeadPassword') && obj.hasOwnProperty('password') && !newErrors.hasOwnProperty('password') && !newErrors.hasOwnProperty('repeadPassword')) {
      const error = this.checkForPasswordEquality((obj as ValidateObjectType).password || '', (obj as ValidateObjectType).repeadPassword || '');

      if (error) {
        newErrors.repeadPassword = error;
      }
    }

    let isError = this.checkErrorValue<T>(newErrors);

    return isError ? newErrors : null;
  }

  static langName(langName: string | undefined): string | null {

    if (!langName) {
      return this.getEmptyError('language')
    }

    return null
  }

  static pose(pose: string | undefined): string | null {

    if (!pose) {
      return this.getEmptyError('pose')
    }

    return null
  }


  static appearance(appearance: string | undefined): string | null {

    if (!appearance) {
      return this.getEmptyError('appearance')
    }

    return null
  }

  static situation(situation: string | undefined): string | null {

    if (!situation) {
      return this.getEmptyError('situation')
    }

    return null
  }

  static answer(answer: string | undefined): string | null {

    if (!answer) {
      return this.getEmptyError('answer')
    }

    return null
  }

  static comment(comment: string | undefined): string | null {

    if (!comment) {
      return this.getEmptyError('comment')
    }

    return null
  }

  static resultText(resultText: string | undefined): string | null {

    if (!resultText) {
      return this.getEmptyError('result')
    }

    return null
  }

  static isTruth(isTruth: string | undefined): string | null {

    if (!isTruth) {
      return this.getEmptyError('field')
    }

    return null
  }

  static isReset(isReset: string | undefined): string | null {

    if (!isReset) {
      return this.getEmptyError('field')
    }

    return null
  }

  static order(order: string | undefined): string | null {

    if (!order) {
      return this.getEmptyError('order')
    }

    return null
  }

  static reward(reward: string | undefined): string | null {

    if (!reward) {
      return this.getEmptyError('reward')
    }

    return null
  }

  static purpose(purpose: string | undefined): string | null {

    if (!purpose) {
      return this.getEmptyError('purpose')
    }

    return null
  }

  static question(question: string | undefined): string | null {

    if (!question) {
      return this.getEmptyError('question')
    }

    return null

  }

  static character(character: string | undefined): string | null {

    if (!character) {
      return this.getEmptyError('character')
    }

    return null
  }

  static history(history: string | undefined): string | null {

    if (!history) {
      return this.getEmptyError('history')
    }

    return null
  }

  static shortName(shortName: string | undefined): string | null {

    if (!shortName) {
      return this.getEmptyError('short name')
    }

    return null
  }

  static nameQuest(nameQuest: string | undefined): string | null {

    if (!nameQuest) {
      return this.getEmptyError('quest name')
    }

    return null
  }

  static qualityItem(qualityItem: string | undefined): string | null {

    if (!qualityItem) {
      return this.getEmptyError('item quality')
    }

    return null
  }


  static level(level: string | undefined): string | null {

    if (!level) {
      return this.getEmptyError('level')
    }

    return null
  }

  static coins(coins: string | undefined): string | null {

    if (!coins) {
      return this.getEmptyError('coins')
    }

    return null
  }

  static finallyUndressCoins(finallyUndressCoins: string | undefined): string | null {

    if (!finallyUndressCoins) {
      return this.getEmptyError('undress coins')
    }

    return null
  }

  static finallyFriendshipCoins(finallyFriendshipCoins: string | undefined): string | null {

    if (!finallyFriendshipCoins) {
      return this.getEmptyError('friendship coins')
    }

    return null
  }

  static nameCharacter(nameCharacter: string | undefined): string | null {

    if (!nameCharacter) {
      return this.getEmptyError('name')
    }

    return null
  }

  static isItemBoost(isItemBoost: string | undefined): string | null {

    if (!isItemBoost) {
      return this.getEmptyError('item boost')
    }

    return null
  }

  static isGiftBoost(isGiftBoost: string | undefined): string | null {

    if (!isGiftBoost) {
      return this.getEmptyError('gift boost')
    }

    return null
  }


  static nameItem(nameItem: string | undefined): string | null {

    if (!nameItem) {
      return this.getEmptyError('name')
    }

    return null
  }

  static nameCombo(nameCombo: string | undefined): string | null {

    if (!nameCombo) {
      return this.getEmptyError('name')
    }

    return null
  }

  static nameGift(nameGift: string | undefined): string | null {

    if (!nameGift) {
      return this.getEmptyError('name')
    }

    return null
  }


  static description(description: string | undefined): string | null {

    if (!description) {
      return this.getEmptyError('description')
    }

    return null
  }

  static coinCost(coinCost: string | undefined): string | null {

    if (!coinCost) {
      return this.getEmptyError('cost')
    }

    if (+coinCost < 0) {
      return "The cost cannot be less than zero"
    }

    return null
  }

  static nameTranslate(nameTranslate: string | undefined): string | null {

    if (!nameTranslate) {
      return this.getEmptyError('translate')
    }

    return null
  }

  static language(language: string | undefined): string | null {

    if (!language) {
      return this.getEmptyError('language')
    }

    return null
  }


  static boost(boost: string | undefined): string | null {

    if (!boost) {
      return this.getEmptyError('boost')
    }

    if (+boost < 0) {
      return "The boost cannot be less than zero"
    }

    return null
  }

  static coinsBox(coinsBox: string | undefined): string | null {

    if (!coinsBox) {
      return this.getEmptyError('coins')
    }

    if (+coinsBox < 0) {
      return "The coins cannot be less than zero"
    }

    return null
  }


  static starsBox(starsBox: string | undefined): string | null {

    if (!starsBox) {
      return this.getEmptyError('stars')
    }

    if (+starsBox < 0) {
      return "The stars cannot be less than zero"
    }

    return null
  }

  static starsCost(starsCost: string | undefined): string | null {

    if (!starsCost) {
      return this.getEmptyError('cost')
    }

    if (+starsCost < 0) {
      return "The cost cannot be less than zero"
    }

    return null
  }



  static timeBlock(timeBlock: string | undefined): string | null {

    if (!timeBlock) {
      return this.getEmptyError('time')
    }

    if (+timeBlock < 0) {
      return "The time cannot be less than zero"
    }

    return null
  }

  static offBlock(offBlock: string | undefined): string | null {

    if (!offBlock) {
      return this.getEmptyError('cost of unlocking')
    }

    if (+offBlock < 0) {
      return "The cost of unlocking cannot be less than zero"
    }

    return null
  }

  static boostItem(boostItem: string | undefined): string | null {

    if (!boostItem) {
      return this.getEmptyError('boost')
    }

    if (+boostItem < 0) {
      return "The boost cannot be less than zero"
    }

    return null
  }


  static tapValue(tapValue: string | undefined): string | null {

    if (!tapValue) {
      return this.getEmptyError('tap value')
    }

    return null
  }

  static phrase(phrase: string | undefined): string | null {

    if (!phrase) {
      return this.getEmptyError('phrase')
    }

    return null
  }

  static translatedPhrase(translatedPhrase: string | undefined): string | null {

    if (!translatedPhrase) {
      return this.getEmptyError('Translation of the phrase')
    }

    return null
  }

  static checkForPasswordEquality(password: string, repeadPassword: string): string | null {
    return password !== repeadPassword ? t('matchPass') : null;
  }

  static getEmptyError(field: string): string {
    return `${t('enter')} ${field}`;
  }

  static getFormatError(field: string): string {
    return `${t('wrongFormat')} ${field}`;
  }

  static checkErrorValue = <T>(objError: T): boolean => {
    for (let fieldErrror in objError) {
      const isErrorValue = objError[fieldErrror];
      if (isErrorValue) {
        return true;
      }
    }

    return false;
  };

  static email(email: string | undefined): string | null {
    if (!email) {
      return this.getEmptyError("Email");
    }

    const pattern = /^[\w.-]+@[\w.-]+\.\w+$/;

    if (!pattern.test(email)) {
      return this.getFormatError("Email");
    }
    return null;
  }



  static phone(phone: string | undefined): string | null {
    if (!phone) {
      return this.getEmptyError(t('phone'));
    }

    if (phone.length < 11) {
      return t('less11chars');
    }

    const pattern =
      /^\d{1,3}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    if (!pattern.test(phone)) {
      return this.getFormatError(t('phoneLc'));
    }

    return null;
  }

  // static inn(inn: string | undefined): string | null {

  //   if (!inn) {
  //     return this.getEmptyError("ИНН");
  //   }

  //   if (inn.length < 10 || inn.length > 12) {

  //     return this.getFormatError("ИНН");
  //   }

  //   return null;
  // }

  static number(number: string | undefined): string | null {
    if (!number) {
      return this.getEmptyError(t('numberLc'));
    }
    return null;
  }

  static title(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError(t('titleLc'));
    }
    return null;
  }

  static content(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError(t('descriptionLc'));
    }
    return null;
  }

  static link(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError(t('linkLc'));
    }

    const pattern = /^(http|https|ftp):/;

    if (!pattern.test(value)) {
      return this.getFormatError(t('linksLc'));
    }

    return null;
  }

  static date(date: string | undefined | Date): string | null {
    if (!date) {
      return t('selectDate');
    }

    return null;
  }

  static dateAddToTest(): string | null {
    return null;
  }

  static dateOfTest(date: string | undefined | Date): string | null {
    return this.date(date)
  }

  static username(name: string | undefined): string | null {

    if (!name) {
      return this.getEmptyError(t('nameLc'));
    }
    return null;
  }

  static message(message: string | undefined): string | null {
    if (!message) {
      return this.getEmptyError(t('messageLc'));
    }
    return null;
  }

  static login(value: string | undefined): string | null {

    if (!value) {
      return this.getEmptyError(t('loginLc'));
    }

    if (value && /\s/.test(value)) {
      return t('loginNoSpace')
    }

    return null;
  }

  static password(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError(t('passwordLc'));
    }

    if (value.length < 8) {
      return t('less8chars');
    }

    return null;
  }

  static oldPassword(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError(t('oldPassLc'));
    }

    if (value.length < 8) {
      return t('less8chars');
    }

    return null;
  }

  static newPassword(value: string | undefined): string | null {
    if (!value) {
      return this.getEmptyError(t('newPassLc'));
    }

    if (value.length < 8) {
      return t('less8chars');
    }

    return null;
  }

  static repeadPassword(repeadPasword: string | undefined): string | null {
    if (!repeadPasword) {
      return t('confirmPassword');
    }

    if (repeadPasword.length < 8) {
      return t('less8chars');
    }

    return null;
  }

  static checkRepead(password: string, repead: string): string | null {
    if (password !== repead) {
      return t('repeatedPassNotMatch');
    }

    return null;
  }

  // static companyName(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите название";
  //   }

  //   return null;
  // }

  // static fio(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите ФИО";
  //   }

  //   return null;
  // }

  // static evidence(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите основание";
  //   }

  //   return null;
  // }

  // static customerTest(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите заказчика испытаний";
  //   }

  //   return null;
  // }

  // static placeTest(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите место проведения";
  //   }

  //   return null;
  // }

  // static supervisorId(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите ID руководителя";
  //   }

  //   return null;
  // }

  // static userId(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите ID пользователя";
  //   }

  //   return null;
  // }

  // static objectTest(value: string | undefined): string | null {
  //   if (!value) {
  //     return "Введите объект испытаний";
  //   }

  //   return null;
  // }

  static updateAvatar(photo: { id: string, result: string | ArrayBuffer | null, file: File | null }): string | null {
    if (!photo) {
      return t('addPhoto');
    }

    if (photo.result && !photo.file) {
      return t('pickNewPhoto');
    }

    return null;
  }

}
