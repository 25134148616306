import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Suspense } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Providers, routerApp } from "./app";
import { BeforeLoading } from "./widgets";
import "./app/language/i18n";

import "./app/styles/globals.scss";

const root = document.getElementById("root");

if (!root) {
  throw new Error("root not found");
}

const container = createRoot(root);

container.render(
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Providers>
      <Suspense fallback={<BeforeLoading />}>
        <RouterProvider router={routerApp} />
      </Suspense>
    </Providers>
  </LocalizationProvider>
);
