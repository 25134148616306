import { Box, Container, Typography } from "@mui/material";
import notFoundPng from "../../../public/assets/images/not_found.png";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            404: {t("The page you are looking for is not here")}
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            {t(
              "You either took a dubious route or got here by mistake. Whatever it is, try using the navigation"
            )}
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <img
              src={notFoundPng}
              alt="Page not found"
              style={{
                marginTop: 50,
                display: "inline-block",
                maxWidth: "100%",
                width: 560,
              }}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export { NotFoundPage };
