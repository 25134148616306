import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import * as page from "../../pages";
import { NAV } from "./nav-links";
import { NotFoundPage } from "../../pages/NotFound/NotFound";
import { MainLayout } from "@/widgets/layouts/main-layout/MainLayout";

export const routerApp = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={`${NAV.MAIN}`} element={<MainLayout />}>
        <Route index element={<page.LoginPage />} />

        <Route path={`${NAV.ACCOUNT}`} element={<page.AccountPage />} />
        <Route path={`${NAV.DASHBOARD}`} element={<page.DashboardPage />} />

        <Route path={`${NAV.USER_INFO}`} element={<page.UserInfoPage />} />
        <Route path={`${NAV.USER_EDIT}`} element={<page.UserEditPage />} />
        {/* <Route path={`${NAV.USER_ADD}`} element={<page.UserAddPage />} /> */}
        <Route path={`${NAV.USER_LIST}`} element={<page.UserListPage />} />

        <Route path={`${NAV.ADMIN_LIST}`} element={<page.AdminListPage />} />
        <Route path={`${NAV.ADMIN_ADD}`} element={<page.AdminAddPage />} />
        <Route path={`${NAV.ADMIN_INFO}`} element={<page.AdminInfoPage />} />

        {/* <Route
          path={`${NAV.FEEDBACK_LIST}`}
          element={<page.FeedBackListPage />}
        />
        <Route
          path={`${NAV.FEEDBACK_EDIT}`}
          element={<page.FeedBackEditPage />}
        /> */}

        <Route
          path={`${NAV.EMAIL_TEMPLATES}`}
          element={<page.EmailTemplateListPage />}
        />
        <Route
          path={`${NAV.EMAIL_HISTORY}`}
          element={<page.EmailHistoryListPage />}
        />
        <Route
          path={`${NAV.EMAIL_CREATE}`}
          element={<page.EmailTemplateCreatePage />}
        />
        <Route
          path={`${NAV.EMAIL_EDIT}`}
          element={<page.EmailTemplateEditPage />}
        />

        <Route path={`${NAV.LOGS_LIST}`} element={<page.AppLogsPage />} />

        {/* <Route path={`${NAV.APP_STATISTICS}`}  element={<page.AppStatisticsPage />} /> */}
        <Route
          path={`${NAV.APP_STATISTICS_EVENTS}`}
          element={<page.AppStatisticsEventsListPage />}
        />
        <Route
          path={`${NAV.APP_STATISTICS_ADD}`}
          element={<page.AppStatisticsEventsAddPage />}
        />
        <Route
          path={`${NAV.APP_STATISTICS_EDIT}`}
          element={<page.AppStatisticsEventsEditPage />}
        />

        <Route path={`${NAV.LOGS}`} element={<page.LogsPage />} />
        {/* <Route
          path={`${NAV.COMMON_SETTINGS}`}
          element={<page.CommonSettingsPage />}
        /> */}
        <Route
          path={`${NAV.UPDATE_SYSTEM}`}
          element={<page.UpdateSystemPage />}
        />

        {/* <Route path={`${NAV.SETTINGS}`} element={<page.SettingsExamplPage />} />

        <Route
          path={`${NAV.CONFIGURATION_KEYS}`}
          element={<page.ConfigurationKeysListPage />}
        />
        <Route
          path={`${NAV.CONFIGURATION_KEYS_ADD}`}
          element={<page.ConfigurationKeysAddPage />}
        />
        <Route
          path={`${NAV.CONFIGURATION_KEYS_EDIT}`}
          element={<page.ConfigurationKeysEditPage />}
        /> */}

        <Route path={`${NAV.NO_PAGE}`} element={<NotFoundPage />} />
        {/* ----------------------Template------------------------ */}

        <Route path={`${NAV.LANGUAGES}`} element={<page.LanguagesPage />} />
        <Route
          path={`${NAV.LANGUAGES_EDIT}`}
          element={<page.LanguageEditPage />}
        />
        <Route path={`${NAV.PHRASES}`} element={<page.PhrasesPage />} />
        <Route
          path={`${NAV.TRANSLATION_ADD}`}
          element={<page.PhraseAddPage />}
        />
        <Route
          path={`${NAV.TRANSLATION_EDIT}`}
          element={<page.PhraseEditPage />}
        />
        <Route path={`${NAV.PHRASES_INFO}`} element={<page.PhraseInfoPage />} />
        <Route path={`${NAV.CHARACTERS}`} element={<page.CharactersPage />} />
        <Route
          path={`${NAV.CHARACTERS_ADD}`}
          element={<page.CharacterAddPage />}
        />
        <Route
          path={`${NAV.CHARACTER_INFO}`}
          element={<page.CharacterInfoPage />}
        />
        <Route
          path={`${NAV.CHARACTER_EDIT}`}
          element={<page.CharacterEditPage />}
        />
        <Route
          path={`${NAV.CHARACTER_FULL_INFO}`}
          element={<page.CharacterFullInfo />}
        />
        <Route
          path={`${NAV.CHARACTER_INFO_ADD}`}
          element={<page.CharacterInfoAddPage />}
        />
        <Route
          path={`${NAV.CHARACTER_INFO_EDIT}`}
          element={<page.CharacterInfoEditPage />}
        />
        <Route path={`${NAV.LEVEL_ADD}`} element={<page.LevelAddPage />} />
        <Route path={`${NAV.LEVEL_EDIT}`} element={<page.LevelEditPage />} />
        <Route
          path={`${NAV.LEVEL_TTF_ADD}`}
          element={<page.LevelTtfAddPage />}
        />
        <Route
          path={`${NAV.LEVEL_TTF_EDIT}`}
          element={<page.LevelTtfEditPage />}
        />
        <Route path={`${NAV.DIALOGS}`} element={<page.DialogsPage />} />
        <Route
          path={`${NAV.DIALOGS_QUESTION_ADD}`}
          element={<page.DialogsQuestionAddPage />}
        />
        <Route
          path={`${NAV.DIALOGS_QUESTION_EDIT}`}
          element={<page.DialogsQuestionEditPage />}
        />
        <Route
          path={`${NAV.DIALOGS_QUESTION_INFO}`}
          element={<page.DialogsQuestionInfoPage />}
        />
        <Route
          path={`${NAV.DIALOGS_ANSWER_ADD}`}
          element={<page.DialogsAnswerAddPage />}
        />
        <Route
          path={`${NAV.DIALOGS_ANSWER_EDIT}`}
          element={<page.DialogsAnswerEditPage />}
        />
        <Route
          path={`${NAV.DIALOG_ANSWER_INFO}`}
          element={<page.DialogAnswerInfoPage />}
        />
        <Route
          path={`${NAV.LEVEL_TTU_LIST}`}
          element={<page.LevelTtuListPage />}
        />
        <Route
          path={`${NAV.LEVEL_TTF_LIST}`}
          element={<page.LevelTtfListPage />}
        />
        <Route path={`${NAV.QUEST_EDIT}`} element={<page.QuestEditPage />} />
        <Route
          path={`${NAV.QUESTONS_QUEST_LEVEL}`}
          element={<page.QuestQuestionsLevelPage />}
        />
        <Route
          path={`${NAV.QUESTONS_QUEST_LEVEL_ADD}`}
          element={<page.QuestQuestionLevelAddPage />}
        />
        <Route
          path={`${NAV.QUESTONS_QUEST_INFO}`}
          element={<page.QuestQuestionInfoLevel />}
        />
        <Route
          path={`${NAV.QUESTONS_QUEST_LEVEL_EDIT}`}
          element={<page.QuestQuestionLevelEditPage />}
        />
        <Route
          path={`${NAV.ANSWER_QUEST_ADD}`}
          element={<page.QuestAnswerAddPage />}
        />
        <Route
          path={`${NAV.ANSWER_QUEST_EDIT}`}
          element={<page.QuestAnswerEditPage />}
        />
        <Route
          path={`${NAV.ANSWER_QUEST_INFO}`}
          element={<page.QuestAnswerInfoPage />}
        />
        <Route path={`${NAV.TEST_TIME}`} element={<page.TestTimePage />} />
        <Route
          path={`${NAV.USER_EVENT_DIALOG}`}
          element={<page.UserDialogEventPage />}
        />
        <Route
          path={`${NAV.USER_EVENT_QUEST}`}
          element={<page.UserQuestEventPage />}
        />
        <Route
          path={`${NAV.USER_REFERRALS}`}
          element={<page.UserReferralsPage />}
        />
        <Route
          path={`${NAV.LEVEL_TTF_INFO}`}
          element={<page.LevelTtfInfoPage />}
        />
        <Route
          path={`${NAV.QUEST_TTF_INFO}`}
          element={<page.QuestTtfInfoPage />}
        />
        <Route path={`${NAV.QUEST_LIST}`} element={<page.QuestListPage />} />
        <Route
          path={`${NAV.QUEST_INFO_GENERAL}`}
          element={<page.QuestInfoPageGenereal />}
        />
        <Route
          path={`${NAV.QUEST_ADD}`}
          element={<page.QuestAddPageGeneral />}
        />
        <Route
          path={`${NAV.GLOBAL_EVENTS}`}
          element={<page.GlobalEventsPage />}
        />
        <Route
          path={`${NAV.GLOBAL_EVENT_INFO}`}
          element={<page.GlobalEventInfoPage />}
        />
        <Route
          path={`${NAV.ITEMS_CHARACTER_PAGE}`}
          element={<page.ItemsCharacterPage />}
        />
        <Route
          path={`${NAV.GIFTS_CHARACTER_PAGE}`}
          element={<page.GiftsCharacterPage />}
        />
        <Route
          path={`${NAV.ITEM_CHARACTER_ADD}`}
          element={<page.ItemsCharacterAddPage />}
        />
        <Route
          path={`${NAV.GIFT_CHARACTER_ADD}`}
          element={<page.GiftsCharacterAddPage />}
        />
        <Route
          path={`${NAV.GIFT_CHARACTER_EDIT}`}
          element={<page.GiftsCharacterEditPage />}
        />
        <Route
          path={`${NAV.ITEM_CHARACTER_EDIT}`}
          element={<page.ItemsCharacterEditPage />}
        />
        <Route path={`${NAV.COIN_BOXES}`} element={<page.CoinBoxesPage />} />
        <Route
          path={`${NAV.COIN_BOXES_ADD}`}
          element={<page.CoinBoxesAddPage />}
        />
        <Route
          path={`${NAV.COIN_BOXES_EDIT}`}
          element={<page.CoinBoxesEditPage />}
        />
        <Route path={`${NAV.COMBO_PAGE}`} element={<page.ComboPage />} />
        <Route path={`${NAV.COMBO_ADD}`} element={<page.ComboAddPage />} />
        <Route path={`${NAV.COMBO_EDIT}`} element={<page.ComboEditPage />} />
        <Route path={`${NAV.GIFT_INFO_PAGE}`} element={<page.GiftInfoPage />} />
        <Route path={`${NAV.ITEM_INFO_PAGE}`} element={<page.ItemInfoPage />} />
      </Route>
    </>
  )
);
