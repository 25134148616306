import { lazy } from "react";

export const AccountPage = lazy(() => import("./Account/Account"));
export const DashboardPage = lazy(() => import("./Dashboard/Dashboard"));
export const UserInfoPage = lazy(() => import("./User/user-info/UserInfo"));
export const UserEditPage = lazy(() => import("./User/user-edit/UserEdit"));
// export const UserAddPage = lazy(() => import("./User/user-add/UserAdd"));
export const UserListPage = lazy(() => import("./User/UserList"));
export const AdminListPage = lazy(() => import('./Admins/AdminList'));
export const AdminAddPage = lazy(() => import('./Admins/admin-add/AdminAdd'));
export const AdminInfoPage = lazy(() => import('./Admins/admin-info/AdminInfo'))
export const FeedBackEditPage = lazy(() => import("./FeedBacks/FeedBackEdit"));
export const FeedBackListPage = lazy(() => import("./FeedBacks/FeedBackList"));
export const EmailTemplateListPage = lazy(
  () => import("./Templates/Email-Templates/EmailTemplateList")
);
export const EmailHistoryListPage = lazy(
  () => import("./Templates/Email-Hstory/EmailHistoryList")
);
export const EmailTemplateCreatePage = lazy(
  () => import("./Templates/Email-Templates/EmailTemplateCreate")
);
export const EmailTemplateEditPage = lazy(
  () => import("./Templates/Email-Templates/EmailTemplateEdit")
);
export const AppLogsPage = lazy(() => import("./AppWork/AppLogs"));
export const AppStatisticsPage = lazy(
  () => import("./AppStatistics/AppStatistics")
);
export const AppStatisticsEventsListPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsList")
);
export const AppStatisticsEventsAddPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsAdd")
);
export const AppStatisticsEventsEditPage = lazy(
  () => import("./AppStatistics/AppStatisticsEventsEdit")
);
export const LogsPage = lazy(() => import("./Systems/Logs/Logs"));
export const CommonSettingsPage = lazy(
  () => import("./Systems/CommonSettings/CommonSettings")
);
export const UpdateSystemPage = lazy(
  () => import("./Systems/UpdateSystem/UpdateSystem")
);
export const SettingsExamplPage = lazy(
  () => import("./Settings/SettingsExampl")
);
export const ConfigurationKeysListPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysList")
);
export const ConfigurationKeysAddPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysAdd")
);
export const ConfigurationKeysEditPage = lazy(
  () => import("./ConfigurationKeys/ConfigurationKeysEdit")
);
export const LoginPage = lazy(() => import("./Login/Login"));
//------Template---------

export const LanguagesPage = lazy(() => import('./Languages/Languages'))
export const LanguageEditPage = lazy(() => import('./Languages/lang-edit/LanguageEdit'))
export const CharactersPage = lazy(() => import('./Characters/Characters'))
export const CharacterAddPage = lazy(() => import('./Characters/character-add/CharacterAdd'))
export const CharacterInfoPage = lazy(() => import('./Characters/character-info/CharacterInfo'))
export const CharacterEditPage = lazy(() => import('./Characters/character-edit/CharacterEdit'))
export const CharacterFullInfo = lazy(() => import('./Characters/character-full-info/CharacterFullInfo'))
export const CharacterInfoAddPage = lazy(() => import('./Characters/character-info-add/CharacterInfoAdd'))
export const CharacterInfoEditPage = lazy(() => import('./Characters/character-info-edit/CharacterInfoEdit'))
export const LevelAddPage = lazy(() => import('./Characters/level-add/LevelAdd'))
export const LevelEditPage = lazy(() => import('./Characters/level-edit/LevelEdit'))
export const PhrasesPage = lazy(() => import('./Languages/phrases/Phrases'))
export const PhraseAddPage = lazy(() => import('./Languages/phrases/phrase-add/PhraseAdd'))
export const PhraseEditPage = lazy(() => import('./Languages/phrases/phrase-edit/PhraseEdit'))
export const PhraseInfoPage = lazy(() => import('./Languages/phrases/phrase-info/PhraseInfo'))
export const LevelTtfAddPage = lazy(() => import('./Characters/level-ttf-add/LevelTtfAdd'))
export const LevelTtfEditPage = lazy(() => import('./Characters/level-ttf-edit/LevelTtfEdit'))
export const DialogsPage = lazy(() => import('./Dialogs/Dialogs'))
export const DialogsQuestionAddPage = lazy(() => import('./Dialogs/question-add/QuestionAdd'))
export const DialogsQuestionEditPage = lazy(() => import('./Dialogs/question-edit/QuestionEdit'))
export const DialogsQuestionInfoPage = lazy(() => import('./Dialogs/question-info/QuestionInfo'))
export const DialogsAnswerAddPage = lazy(() => import('./Dialogs/answer-add/AnswerAdd'))
export const DialogsAnswerEditPage = lazy(() => import('./Dialogs/answer-edit/AnswerEdit'))
export const DialogAnswerInfoPage = lazy(() => import('./Dialogs/answer-info/AnswerInfo'))
export const LevelTtuListPage = lazy(() => import('./Characters/level-ttu-list/LevelTtuList'))
export const LevelTtfListPage = lazy(() => import('./Characters/level-ttf-list/LevelTtfList'))
export const QuestEditPage = lazy(() => import('./Dialogs/quest-edit/QuestEdit'))
export const QuestQuestionsLevelPage = lazy(() => import('./Dialogs/quest-question-level-list/QuestQuestionLevelList'))
export const QuestQuestionLevelAddPage = lazy(() => import('./Dialogs/quest-question-level-add/QuestQuestionLevelAdd'))
export const QuestQuestionInfoLevel = lazy(() => import('./Dialogs/quest-question-info/QuestQuestionInfo'))
export const QuestQuestionLevelEditPage = lazy(() => import('./Dialogs/quest-question-level-edit/QuestQuestionLevelEdit'))
export const QuestAnswerAddPage = lazy(() => import('./Dialogs/quest-answer-add/QuestAnswerAdd'))
export const QuestAnswerEditPage = lazy(() => import('./Dialogs/quest-answer-edit/QuestAnswerEdit'))
export const QuestAnswerInfoPage = lazy(() => import('./Dialogs/quest-answer-info/QuestAnswerInfo'))
export const TestTimePage = lazy(() => import('./TestTime/TestTime'))
export const UserDialogEventPage = lazy(() => import('./User/user-dialog-event/UserDialogEvent'))
export const UserQuestEventPage = lazy(() => import('./User/user-quest-event/UserQuestEvent'))
export const UserReferralsPage = lazy(() => import('./User/user-referrals/UserReferrals'))
export const LevelTtfInfoPage = lazy(() => import('./Characters/level-ttf-info/LevelTtfInfo'))
export const QuestTtfInfoPage = lazy(() => import('./Characters/quest-info/QuestInfo'))
export const QuestListPage = lazy(() => import('./Characters/quest-list/QuestList'))
export const QuestInfoPageGenereal = lazy(() => import('./Characters/quest-info/QuestInfoGeneral'))
export const QuestAddPageGeneral = lazy(() => import('./Dialogs/quest-add/QuestAddGeneral'))
export const GlobalEventsPage = lazy(() => import('./GlobalEvents/GlobalEvents'))
export const GlobalEventInfoPage = lazy(() => import('./GlobalEvents/EventInfo/EventInfo'))
export const ItemsCharacterPage = lazy(() => import('./Items/ItemsPage'))
export const GiftsCharacterPage = lazy(() => import('./Gifts/GiftsPage'))
export const ItemsCharacterAddPage = lazy(() => import('./Items/item-add/ItemAddPage'))
export const GiftsCharacterAddPage = lazy(() => import('./Gifts/gift-add/GiftAddPage'))
export const GiftsCharacterEditPage = lazy(() => import('./Gifts/gift-edit/GiftEdit'))
export const ItemsCharacterEditPage = lazy(() => import('./Items/item-edit/ItemEdit'))
export const CoinBoxesPage = lazy(() => import('./CoinBoxes/CoinBoxes'))
export const CoinBoxesAddPage = lazy(() => import('./CoinBoxes/add-box/CoinBoxAdd'))
export const CoinBoxesEditPage = lazy(() => import('./CoinBoxes/edit-box/CoinBoxEdit'))
export const ComboPage = lazy(() => import('./Combo/ComboPage'))
export const ComboAddPage = lazy(() => import('./Combo/combo-add/ComboAddPage'))
export const ComboEditPage = lazy(() => import('./Combo/combo-edit/ComboEditPage'))
export const GiftInfoPage = lazy(() => import('./Gifts/gift-info/GiftInfoPage'))
export const ItemInfoPage = lazy(() => import('./Items/item-info/ItemInfoPage'))
