const statistics = {
  getSatisticEvents: 'statistics/events/statistics',
  createEvent: 'statistics/events',
  getStatisticInfo: (id: string) => `statistics/events/${id}`,
  updateStatisticEvent: (id: string) => `statistics/events/${id}`,
  removeStatisticEvetn: (id: string) => `statistics/events/${id}`
}

const emailEndpoints = {
  getAllTemplates: 'template',
  getHystorys: 'email/history',
  removeHysrotys: 'email/history',
  getTemplates: 'template/keys',
  createTemplates: 'template',
  templateInfo: (id: string) => `template/${id}`,
  getTemplateKeys: 'template/keys',
  templateUpdate: (id: string) => `template/${id}`,
  getSmtp: 'smtp',
  editSmtp: 'smtp',
  removeTemplate: 'admin/setting',
}

const logsEndpoints = {
  getAllLogs: 'logs/frontend',
  removeFrontendLogs: 'logs/frontend',
  getLogs: 'logs',
  removeLogs: 'logs',
}

const systemEndpoints = {
  getBackendExport: 'system/backend/export',
  backendImport: 'system/backend/import',
}

const adminEndpoints = {
  getUsers: 'admin/users',
  changePassword: 'admin/current/password',
  createUser: 'admin/users',
  getUser: (id: string) => `admin/users/${id}`,
  getUserForm: (id: string) => `admin/user/${id}`,
  editUserAvatar: (id: string) => `admin/users/avatar/${id}`,
  editUserInfo: (id: string) => `admin/users/${id}`,
  editUserPassword: (id: string) => `admin/users/password/${id}`,
  deleteUser: (id: string) => `admin/users/${id}`
}


const feedbackEndpoints = {
  getInfo: (id: string) => `feedback/${id}`,
  edit: (id: string) => `feedback/${id}`,
  getAll: 'feedback',
  remove: (id: string) => `feedback/${id}`,
}

const configurationEndroints = {
  getKeyInfo: (id: string) => `configuration/keys/${id}`,
  keyEdit: (id: string) => `configuration/keys/${id}`,
  getAllKeys: 'configuration/keys/',
  removeKey: (id: string) => `configuration/keys/${id}`,
  createKey: 'configuration/keys',
}

const userEndpoints = {
  getCurrent: 'users/current',
  changeAvatar: 'users/current/avatar',
  changeInfo: 'users/current',
  getRoles: 'roles',
  editUser: 'users/current',
  editPassword: 'users/current/password',
  editUserAvatar: 'users/avatar',
  changePassword: 'users/current/password',
}

const authEndpoints = {
  login: 'login',
  register: 'requests',
  recoveryPassword: 'change/password',
  recoveryChangePassword: (key: string) => `change/password/recovery/${key}`,
}
//-------------Template-------------

const langEndpoints = {
  languages: 'languages',
  createLanguage: 'languages',
  remove: (id: string) => `languages/${id}`,
  getLang: (id: string) => `languages/${id}`,
  updateLang: (id: string) => `languages/${id}`
}

const characterEndpoints = {
  characters: 'girls',
  createCharacter: 'girls',
  getCharacter: (id: string) => `girls/${id}`,
  updateCharacter: (id: string) => `girls/${id}`,
  getAllInfo: (id: string) => `girls_info?girl_id=${id}`,
  getInfoById: (girlId: string, langId: string) => `girls_info?girl_id=${girlId}&language_id=${langId}`,
  createGirlInfo: 'girls_info',
  updatGirlInfo: 'girls_info',
  removeGirlInfo: (girlId: string, langId: string) => `girls_info?girl_id=${girlId}&language_id=${langId}`,
  updatePreviewImg: (girlId: string) => `girls/preview/${girlId}`,
  updateBackgroundImg: (girlId: string) => `girls/background/${girlId}`,
  updatefriendshipImgs: (girlId: string) => `girls/friendship/${girlId}`,
  deletFiendshipimg: (girlId: string, imgName: string) => `girls/friendship/${girlId}?img=${imgName}`
}

const levelEndpoints = {
  createLevel: 'tap/undress',
  getLevelById: (girlId: string) => `tap/undress/${girlId}`,
  getLevelTTFById: (girlId: string) => `tap/friendship/${girlId}`,
  updateLevel: 'tap/undress',
  updateLevelTtf: 'tap/friendship',
  deleteLevel: 'tap/undress/delete',
  getLevelByIdAndLevel: (girlId: string, level: string) => `tap/undress/level/${girlId}?level=${level}`,
  createTtfLevel: 'tap/friendship',
  getLevelTtfByIdAndLevel: (girlId: string, level: string) => `tap/friendship/level/${girlId}?level=${level}`,
  deleteLevelTtf: 'tap/friendship/delete',
  deleteLevelTtfByLevelId: (levelId: string) => `tap/friendship/${levelId}`,
  getUnicLevelByLevelId: (levelId: string) => `tap/friendship/unique/${levelId}`,
  getLevelsTtfById: (girlId: string) => `tap/friendship/${girlId}`,
  updateLevelTtfByUnicLevelId: (levelId: string) => `tap/friendship/${levelId}`
}

const phrasesEndpoints = {
  createPhrase: 'translations',
  getPhrases: 'translations',
  getPhraseById: (phraseId: string) => `translations/${phraseId}`,
  updatePhrase: (phraseId: string) => `translations/${phraseId}`,
  removePhraseTranslation: (phraseId: string) => `translations/${phraseId}`,
  updateTranslate: 'translations/phrases'
}

const dialoguesEndpoints = {
  getAllQuestionsForGirl: (girlId: string) => `friendship/questions/girl/${girlId}`,
  createQuestion: 'friendship/questions',
  getQuestionByQuestionId: (questionId: string) => `friendship/questions/${questionId}`,
  updateQuestionById: (questionId: string) => `friendship/questions/${questionId}`,
  deleteQuestionById: (questionId: string) => `friendship/questions/${questionId}`,
  getAnswers: `friendship/answers`,
  createAnswer: 'friendship/answers',
  deleteAnswerById: (answerId: string) => `friendship/answers/${answerId}`,
  getAnswerById: (answerId: string) => `friendship/answers/${answerId}`,
  updateAnswerById: (answerId: string) => `friendship/answers/${answerId}`
}

const questEndpoints = {
  createQuest: `events/dialogs`,
  getQuestByLevelTtfId: (levelId: string) => `events/dialogs/level/${levelId}`,
  getAllQuests: `events/dialogs`,
  getAllQuestForGirl: (girlId: string) => `events/dialogs/girl/${girlId}`,
  updateQuestByQuestId: (questId: string) => `events/dialogs/${questId}`,
  deleteQuest: (questId: string) => `events/dialogs/${questId}`,
  getLevelsForQuestByQuestId: (questId: string) => `events/dialogs/${questId}`,
  getQuestByQuestId: (questId: string) => `events/dialogs/${questId}`
}

const questQuestionEndpoints = {
  createQuestion: `events/dialogs/questions`,
  getAllQuestionsByQuestId: (questId: string) => `events/dialogs/questions/level/${questId}`,
  getQuestionByQuestId: (questId: string) => `events/dialogs/questions/${questId}`,
  deleteQuestionById: (questionId: string) => `events/dialogs/questions/${questionId}`,
  updateQuestionByQuestionID: (questionId: string) => `events/dialogs/questions/${questionId}`
}

const questAnswerEndpoints = {
  createAnswer: `events/dialogs/answers`,
  getAllAnswers: `events/dialogs/answers/all`,
  getAnswersByQuestionId: (questionId: string) => `events/dialogs/answers/question/${questionId}`,
  deleteAnswer: (answerId: string) => `events/dialogs/answers/${answerId}`,
  getAnswerById: (answerId: string) => `events/dialogs/answers/${answerId}`,
  updateAnswerById: (answerId: string) => `events/dialogs/answers/${answerId}`
}

const timeEndpoints = {
  getServerTime: 'global_time/server_time',
  updateServerTime: 'global_time',
  getServerTimeAndConfig: 'global_time'
}

const eventsEndpoints = {
  getHistoryDialogs: 'users/history/dialogs',
  getHistoryQuestDialogs: 'users/history/quests',
  getHistoryDialogsByEventId: (eventId: string) => `users/history/dialogs/${eventId}`,
  getHistoryQuestEventByEventId: (eventId: string) => `users/history/quests/${eventId}`
}

const referralsEndpoints = {
  getReferralsForUserById: (userId: string) => `referral/${userId}`
}

const globalEventsEndpoints = {
  getAllEvents: 'events/history/info',
  getEventById: (eventId: string) => `events/history/info/${eventId}`,
  getEventsHistory: `events/history`
}

const giftAndItemEndpoints = {
  createItemOrGift: 'gifts_and_items',
  getAllItemsAndGifts: 'gifts_and_items',
  deleteItem: (itemId: string) => `gifts_and_items/${itemId}`,
  getItemsForGirl: (girlId: string) => `gifts_and_items/girl/${girlId}`,
  getItemById: (itemId: string) => `gifts_and_items/${itemId}`,
  updateItemByid: (itemId: string) => `gifts_and_items/${itemId}`,
  getAllItemsForGirl: (girlId: string) => `gifts_and_items/girl/all/${girlId}`,
  postCsv: `gifts_and_items/upload-csv`
}

const coinsEndpoints = {
  getCoinBoxes: 'coins_box',
  createCoinBox: 'coins_box',
  getCoinBoxById: (boxId: string) => `coins_box/${boxId}`,
  updateCoinBoxById: (boxId: string) => `coins_box/${boxId}`,
  deleteCoinBoxById: (boxId: string) => `coins_box/${boxId}`
}

const comboEndpoints = {
  getCombo: 'combo',
  createCombo: 'combo',
  getComboById: (comboId: string) => `combo/${comboId}`,
  updateCombo: (comboId: string) => `combo/${comboId}`,
  deleteComboId: (comboId: string) => `combo/${comboId}`,
  getComboForGirlById: (girlId: string) => `combo/girl/${girlId}`

}

export const ENDPOINT_LIST = {
  adminEndpoints,
  userEndpoints,
  statistics,
  logsEndpoints,
  configurationEndroints,
  feedbackEndpoints,
  systemEndpoints,
  emailEndpoints,
  authEndpoints,

  langEndpoints,
  characterEndpoints,
  levelEndpoints,
  phrasesEndpoints,
  dialoguesEndpoints,
  questEndpoints,
  questQuestionEndpoints,
  questAnswerEndpoints,
  timeEndpoints,
  eventsEndpoints,
  referralsEndpoints,
  globalEventsEndpoints,
  giftAndItemEndpoints,
  coinsEndpoints,
  comboEndpoints
}
