export const METODATA_TITLES: Record<string, string> = {
  '/dashboard': 'currentAppeals',
  '/users': 'players',
  // '/users/add': 'addUser',
  '/admins': 'admins',
  '/admins/add': 'addAdmins',
  // '/feedbacks': 'feedback',
  '/email-templates': 'mailTemplatesTitle',
  '/email-templates/create': 'createMailtemplate',
  '/email-history': 'emailHistory',
  '/settings': 'settings',
  '/configuration/keys': 'keyConfigurator',
  '/configuration/keys/add': 'keyConfiguratorAdd',
  '/app-logs': 'appLog',
  '/app-statistics': 'appStatistics',
  '/events': 'events',
  '/events/add': 'addEvents',
  '/common-settings': 'commonSettings',
  '/logs': 'logs',
  '/update-system': 'systemUpdate',
  '/login': 'authorization',
  '/account': 'account',
  //----template--------
  '/languages': 'languages',
  '/characters': 'characters',
  '/phrases': 'phrases',
  '/time-test': 'editTime',
  '/global-events': 'globalEvents',
  '/coin-boxes': 'coinBoxes',

}

export const METODATA_TITLES_DINAMIC: Record<string, string> = {
  '/users/info/': 'playerInformation',
  '/users/edit/': 'editPlayer',
  '/admins/info': 'adminInformation',
  '/feedbacks/edit/': 'editReview',
  '/email-templates/edit/': 'updateMailTemplate',
  '/events/edit/': 'editingEvent',
  '/coin-boxes/': 'coinBoxes',
  //----template--------

  '/languages/edit/': 'editLang',
  '/character/add': 'addCharacters',
  '/phrases/add': 'addPhrase',
  '/phrases/info': 'phrase',
  '/phrases/edit': 'editPhrase',
  '/character/info': 'charInfo',
  '/character/edit/': 'charEdit',
  '/character/info/full': 'fullInfo',
  '/character/dialogs/': 'dialogs',
  '/character/quests': 'quests',
  '/global-events/event': 'globalEvents',
  '/character/items': 'items',
  '/character/gifts': 'gifts',
  '/character/combo': 'combo',
}
